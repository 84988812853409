.tooltip-container {
    position: relative;
}

.tooltip-content {
    position: absolute;
    background-color: #5C6BC0;
    text-align: center;
    color: #fff;
    transform: translateX(-50%);
    white-space: nowrap;
    border-radius: 4px;
    padding: 6px 10px;
    z-index: 999;
}

.tooltip-content:before {
    content: "";
    position: absolute;
}

/* POSITION TOP */
.tooltip-content.top {
    bottom: 100%;
    left: 50%;
    margin-bottom: 10px;
}

.tooltip-content.top:before {
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #5C6BC0;
}