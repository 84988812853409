@tailwind base;
@tailwind components;
@tailwind utilities;

.header-title {
  font-family: 'Futura PT';
  font-style: italic;
  font-weight: 450;
  margin-left: 10px;
  font-size: 20px;
  line-height: 31px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  /* 03 - #5C6BC0 */
  color: #5C6BC0;
}

.header-badge {
  width: 60px;
  height: 24px;
  background: #5C6BC0;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}

.add-new-row {
  background: white;
  height: 2px;
  width: 100%;
  position: relative;
  z-index: 10;
}

.add-new-row .add-new-row-container {
  display: none;
  position: absolute;
  left: 20%;
  top: -6px;
  height: 12px
}

.add-new-row .add-new-row-container .blank-space {
  position: absolute;
  left: 0;
  top: 6px;
  background: white;
  height: 2px;
  width: 30px;
}

.add-new-row .add-new-row-container .add-new-row-icon {
  position: absolute;
  left: 9px;
  top: 1px;
}

.add-new-row:hover {
  background: #5C6BC0;
}

.add-new-row:hover .add-new-row-container {
  display: block;
}

.add-new-row:hover .add-new-row-container:hover {
  display: block;
}

.otpContainer {
  display: flex;
  justify-content: center;
}

.otpInput {
  width: 40px;
  height: 48px;
  margin: 0 4px;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #CBD5E1;
}

.form-check-input:checked {
  background-color: #5C6BC0 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 7px !important;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #CBD5E1;
  outline: 1px solid #CBD5E1;
  border-radius: 2px;
}